import React, { useEffect, useContext, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import PropTypes from 'prop-types';

import PageError from './PageError';

const ERROR_MESSAGE = {
  TITLE: 'Oh no! Something went wrong.',
  SUBTITLE:
    'Please refresh the page. If the error persists reach out to your system administrator.'
};

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const { refreshLogin } = useAuth();

  const handleTokenError = async () => {
    try {
      await refreshLogin();
      setHasError(false);
      window.location.reload();
    } catch (refreshError) {
      localStorage.clear();
      window.location.reload();
    }
  };

  useEffect(() => {
    const handleError = error => {
      if (error.message.includes('[TOKENERROR]')) {
        handleTokenError();
      }
    };

    window.addEventListener('error', handleError);
    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  if (hasError) {
    return (
      <PageError
        title={ERROR_MESSAGE.TITLE}
        subtitle={ERROR_MESSAGE.SUBTITLE}
        notFoundPage={false}
      />
    );
  }

  return children;
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
