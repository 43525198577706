import { makeStyles } from '@material-ui/core/styles';
import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
    '& .MuiInputBase-input.Mui-disabled': {
      '@media print': {
        color: 'black'
      }
    },
    '& .MuiSelect-root': {
      '@media print': {
        padding: 16
      }
    },
    '@media print': {
      display: 'flex',
      margin: 5
    }
  },
  supervisorRoot: {
    display: 'flex'
  },
  questionText: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`,
    fontSize: '18px',
    '@media print': {
      fontSize: '12px'
    }
  },
  feedbackText: {
    '@media print': {
      fontSize: '16px',
      paddingTop: 30
    }
  },
  internshipYearsHeader: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`,
    fontSize: '18px',
    '@media print': {
      fontSize: 18,
      alignSelf: 'flex-end',
      paddingTop: 30,
      marginRight: 8
    }
  },
  formControl: {
    minWidth: 150,
    margin: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
    '@media print': { paddingRight: 10, marginBottom: 0, fontSize: '1rem' },
    '& .MuiSelect-root': {
      '@media print': {
        padding: 5
      }
    },
    '& .MuiSelect-icon': {
      '@media print': {
        display: 'none'
      }
    },
    '& .MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
      '@media print': {
        transform: 'none',
        padding: 5,
        height: '1.1876em'
      }
    }
  },
  radioButtonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center'
    },
    '@media print': {
      display: 'block',
      marginTop: '30px'
    }
  },
  description: {
    fontSize: '1.25rem',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontWeight: '400'
    },
    '@media print': {
      fontSize: '12px'
    }
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'left'
    }
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '65%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: `${theme.spacing(3)}px ${theme.spacing(0)}px`
    },
    '@media print': {
      margin: 5
    }
  },
  dropdowns: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '35%',
    [theme.breakpoints.down('md')]: {
      width: '60%'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%'
    },
    '@media print': {
      display: 'block',
      marginLeft: 20,
      fontSize: '12px',
      maxWidth: '25%'
    }
  },
  internshipSectionRadioButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 30,
    '& p': {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
    '@media print': {
      display: 'flex',
      marginTop: 0,
      marginBottom: 10
    }
  },
  internshipText: {
    fontWeight: 'bold',
    fontSize: 18,
    '@media print': {
      display: 'block',
      marginTop: 40,
      fontSize: 14
    }
  },
  internAttendanceText: {
    fontWeight: 'bold',
    fontSize: 18,
    '@media print': {
      display: 'block',
      marginTop: 0,
      fontSize: 14
    }
  },
  internReviewRadioButtonContainer: {
    marginBottom: 47,
    height: 300,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: 'none'
    }
  },
  attendanceRadioButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    '@media print': {
      width: '50%',
      marginBottom: 0
    }
  },
  openEndedQuestions: {
    width: '100%'
  },
  feedbackSectionRadioButtonContainer: {
    width: '100%',
    display: 'flex',
    marginTop: 30,
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
    '@media print': {
      display: 'flex',
      marginTop: 0
    }
  },
  questionDescriptionHeader: {
    color: globalTheme.palette.primary.main
  },
  questionText: {
    marginBottom: 10,
    '@media print': {
      marginBottom: 0
    }
  }
}));

export default useStyles;
