import React, { useState, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useApolloClient } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Button, TextField, Link } from '@material-ui/core';
import {
  CancelOutlined,
  Share,
  SaveOutlined,
  DeleteForever
} from '@material-ui/icons';

import { useGoalState, useGoalDispatch } from '../../context/GoalContext';
import { useAuth } from '../../context/AuthContext';
import { FETCH_GOAL_COMMENTS, GET_USER_ROLES } from '../../graphql/goalQueries';
import {
  CREATE_GOAL,
  UPDATE_GOAL,
  DELETE_GOAL,
  CREATE_GOAL_COMMENT,
  DELETE_GOAL_COMMENTS
} from '../../graphql/goalMutations';
import {
  GOAL_STATUS,
  GOAL_TRACK,
  GOAL_CATEGORY,
  MODAL,
  ACTION,
  COMPETENCY_URL,
  allCompetenciesToDisplay
} from '../../helpers/constants';
import { getErrorMessage, idMatch } from '../../helpers/helpers';
import GoalCommentSection from './goalComments/GoalCommentSection';
import ShareGoalSection from './ShareGoalSection';
import GoalDatesSection from './GoalDatesSection';
import GoalTitleAndDescription from './GoalTitleAndDescription';
import {
  CancelModal,
  DeleteModal,
  ErrorModal,
  UnshareModal
} from './GoalModals';
import { AutoScroll, Dropdown, LoadingSpinner } from '../common';
import {
  getDropdownSelectionOptions,
  getSelectedOptionId,
  formatDate
} from '../../helpers/goalFormHelpers';
import useStyles from '../../styles/goals/goalFormStyles';

const GoalForm = () => {
  const {
    state: { goalFormConfig, unsavedGoalChanges }
  } = useGoalState();

  const { userData } = useAuth();
  const { goalDispatch } = useGoalDispatch();
  const classes = useStyles();
  const history = useHistory();
  const client = useApolloClient();
  const normalizeDate = date => date.slice(0, 10);

  const {
    goalId,
    shareToName,
    shareToEmail,
    shareToEmployeeId,
    goalForName,
    goalForEmail,
    goalForEmployeeId,
    goalForJobNumber,
    goalTitle,
    goalDescription,
    category,
    competency,
    status,
    track,
    startDate,
    dueDate,
    completedDate,
    createdForFlag,
    shareToFlag
  } = goalFormConfig;

  // used to check for form changes
  const goalConfigCopy = useMemo(() => {
    return {
      ...goalFormConfig,
      startDate: startDate && normalizeDate(startDate),
      dueDate: dueDate && normalizeDate(dueDate),
      completedDate: completedDate && normalizeDate(completedDate),
      competency: competency && allCompetenciesToDisplay(false)
    };
  }, [goalFormConfig, startDate, dueDate, completedDate, competency]);

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState();
  const [error, setError] = useState(null);
  const [sharedWithError, setSharedWithError] = useState('');
  const [updatedCommentText, setCommentText] = useState('');
  const [commentsToDelete, setCommentsToDelete] = useState([]);
  const [goalForRole, setGoalForRole] = useState('');
  const [textField, setTextField] = useState({
    goalTitle,
    goalDescription
  });
  const [sharedWith, setSharedWith] = useState({
    name: shareToName,
    email: shareToEmail
  });
  const [shareToUser, setShareToUser] = useState({
    name: null,
    email: null,
    employeeId: null
  });

  const [dropdownSelection, setDropdownSelection] = useState({
    Category: category || GOAL_CATEGORY.NONE.NAME,
    Competency: competency || allCompetenciesToDisplay(true),
    Status: status || GOAL_STATUS.NOT_STARTED.NAME,
    Track: track || GOAL_TRACK.NO_FLAG_SET.NAME
  });

  const [dateInput, setDateInput] = useState({
    Start: startDate || null,
    Due: dueDate || null,
    Completed: completedDate || null
  });

  // Mutations
  const [createGoal] = useMutation(CREATE_GOAL);
  const [updateGoal] = useMutation(UPDATE_GOAL);
  const [deleteGoal] = useMutation(DELETE_GOAL);
  const [createGoalComment] = useMutation(CREATE_GOAL_COMMENT);
  const [deleteGoalComments] = useMutation(DELETE_GOAL_COMMENTS);

  const {
    data: prevCommentsData,
    loading: fetchCommentsLoading,
    refetch: refetchComments
  } = useQuery(FETCH_GOAL_COMMENTS, {
    variables: { goalId },
    skip: !goalId,
    fetchPolicy: 'no-cache'
  });

  // fetch the azure role of the user the goal is for
  useEffect(() => {
    const fetchRole = (token, email) => {
      return client.query({
        query: GET_USER_ROLES,
        variables: { token, email }
      });
    };

    const updateRole = async () => {
      const token = localStorage.getItem('accessToken');

      try {
        const {
          data: { getUserRoles }
        } = await fetchRole(token, goalForEmail);
        setGoalForRole(getUserRoles);
      } catch (err) {
        const message = getErrorMessage(err);
        throw new Error(`[ERROR] Failed to fetch user role: ${message}`);
      }
    };

    if (!goalId && goalForEmail) updateRole();
  }, [client, goalForEmail, goalId]);

  // checks when changes are made to the current form
  useEffect(() => {
    const start = dateInput.Start && normalizeDate(dateInput.Start);
    const due = dateInput.Due && normalizeDate(dateInput.Due);
    const complete = dateInput.Completed && normalizeDate(dateInput.Completed);

    const goalFormData = {
      goalId: goalConfigCopy.goalId,
      category: dropdownSelection.Category || goalConfigCopy.category,
      competency: dropdownSelection.Competency || goalConfigCopy.competency,
      track: dropdownSelection.Track || goalConfigCopy.track,
      status: dropdownSelection.Status || goalConfigCopy.status,
      shareToName: goalConfigCopy.shareToName,
      shareToEmail: goalConfigCopy.shareToEmail,
      shareToEmployeeId: goalConfigCopy.shareToEmployeeId,
      goalForName: goalConfigCopy.goalForName,
      goalForEmail: goalConfigCopy.goalForEmail,
      goalForEmployeeId: goalConfigCopy.goalForEmployeeId,
      goalForJobNumber: goalConfigCopy.goalForJobNumber,
      goalTitle: textField.goalTitle || goalConfigCopy.goalTitle,
      goalDescription:
        textField.goalDescription || goalConfigCopy.goalDescription,
      startDate: start || goalConfigCopy.startDate,
      dueDate: due || goalConfigCopy.dueDate,
      completedDate: complete || goalConfigCopy.completedDate,
      commentText: updatedCommentText || goalConfigCopy.commentText,
      createdForFlag: goalConfigCopy.createdForFlag,
      shareToFlag: goalConfigCopy.shareToFlag
    };

    const registerChanges = bool => {
      goalDispatch({
        type: 'setUnsavedGoalChanges',
        payload: bool
      });
    };
    const stringifiedFormConfig = JSON.stringify(goalConfigCopy);
    const stringifiedStateConfig = JSON.stringify(goalFormData);

    const changesMade =
      stringifiedFormConfig !== stringifiedStateConfig ||
      commentsToDelete.length;
    registerChanges(changesMade);
  }, [
    goalConfigCopy,
    commentsToDelete,
    goalDispatch,
    dropdownSelection,
    dateInput,
    textField,
    updatedCommentText
  ]);

  // Conditionals
  const cancelModalIsOpen = () => openModal === MODAL.CANCEL;
  const deleteModalIsOpen = () => openModal === MODAL.DELETE;
  const errorModalIsOpen = () => openModal === MODAL.ERROR;
  const unshareModalIsOpen = () => openModal === MODAL.UNSHARE;
  const goalIsForCurrentUser =
    idMatch(goalForEmail, userData.email) ||
    idMatch(goalForEmployeeId, userData.employeeId);
  const currentUserCanEdit = () => goalIsForCurrentUser || !goalId;
  const sharedWithCurrentUser =
    idMatch(shareToEmail, userData.email) ||
    idMatch(shareToEmployeeId, userData.employeeId);

  // assemble current state of goal data
  const getGoalContents = () => {
    const categoryId = getSelectedOptionId(
      GOAL_CATEGORY,
      dropdownSelection.Category
    );
    const statusId = getSelectedOptionId(GOAL_STATUS, dropdownSelection.Status);
    const trackId = getSelectedOptionId(GOAL_TRACK, dropdownSelection.Track);
    const competencyId = getSelectedOptionId(
      allCompetenciesToDisplay(false),
      dropdownSelection.Competency
    );

    const completedDateObject = formatDate(dateInput.Completed);
    const dueDateObject = formatDate(dateInput.Due);
    const startDateObject = formatDate(dateInput.Start);
    return {
      categoryId,
      statusId,
      trackId,
      competencyId,
      completedDateObject,
      dueDateObject,
      startDateObject
    };
  };

  const updateExistingGoal = async (user, createNotify, shareNotify) => {
    const {
      categoryId,
      statusId,
      trackId,
      competencyId,
      completedDateObject,
      dueDateObject,
      startDateObject
    } = getGoalContents();

    // user goal is created for may need visual indicator on dashboard
    const flagUserCreatedFor = () => {
      if (createNotify !== undefined) return createNotify;
      if (goalIsForCurrentUser) return false;
      if (createdForFlag || updatedCommentText) return true;
      return false;
    };

    // user being shared to may need visual indicator on dashboard
    const flagUserSharedTo = () => {
      if (shareNotify !== undefined) return shareNotify;
      if (sharedWithCurrentUser) return false;
      if (shareToFlag || updatedCommentText || shareToUser.email) return true;
      return false;
    };

    await updateGoal({
      variables: {
        goalId,
        title: textField.goalTitle || goalTitle,
        description: textField.goalDescription || goalDescription,
        startDate: startDateObject,
        dueDate: dueDateObject,
        completedDate: completedDateObject,
        categoryId,
        competencyId,
        statusId,
        trackId,
        shareToName: user.name,
        shareToEmail: user.email,
        shareToEmployeeId: user.employeeId,
        createdForFlag: flagUserCreatedFor(),
        shareToFlag: flagUserSharedTo()
      }
    });
  };

  const useMountEffect = cbFn => useEffect(cbFn, []);

  const resetNotificationFlag = () => {
    const user = { name: undefined, email: undefined, employeeId: undefined };
    if (goalIsForCurrentUser && createdForFlag) {
      updateExistingGoal(user, false, shareToFlag);
    }
    if (sharedWithCurrentUser && shareToFlag) {
      updateExistingGoal(user, createdForFlag, false);
    }
  };

  useMountEffect(resetNotificationFlag);

  const selectDropdownOption = dropdown => e => {
    setDropdownSelection({
      ...dropdownSelection,
      [dropdown]: e.target.value
    });
  };

  const handleDateSelection = dateField => e => {
    const selectedDate = e ? moment(e).format('YYYY-MM-DD HH:mm') : null;
    setDateInput({ ...dateInput, [dateField]: selectedDate });
  };

  const closeModal = () => {
    setOpenModal(null);
    setError(null);
  };

  const handleError = action => {
    setIsLoading(false);
    setError(action);
    setOpenModal(MODAL.ERROR);
  };

  const redirectToGoalsDashboard = React.useCallback(() => {
    closeModal();
    history.push('/goalsDashboard');
  }, [history]);

  if (isLoading || fetchCommentsLoading) {
    return <LoadingSpinner />;
  }

  const storeComment = id => {
    return createGoalComment({
      variables: {
        goalId: id,
        comment: updatedCommentText
      }
    });
  };

  const setGoalTitleOrDescription = (label, value) => {
    setTextField({ ...TextField, [label]: value });
  };

  // creates or updates goal details and shares with a given user
  const shareGoal = async () => {
    if (shareToUser.name) {
      setSharedWithError('');
      setIsLoading(true);
      try {
        if (goalId) {
          const user = getShareToUser();
          const goalPromise = updateExistingGoal(user, false, true);
          goalDispatch({
            type: 'setGoalFormConfig',
            payload: {
              shareToFlag: true
            }
          });
          const commentPromise = updateComments(goalId);
          await Promise.all([goalPromise, commentPromise]);
        } else {
          const newGoalId = await createNewGoal();
          await updateComments(newGoalId);
        }
        refetchComments();
        setSharedWith({ ...shareToUser });
        setIsLoading(false);
        // if user is sharing on creation, they should no longer have access
        if (!goalIsForCurrentUser && !sharedWithCurrentUser) {
          redirectToGoalsDashboard();
        }
      } catch {
        handleError(ACTION.SHARE);
      }
    } else {
      setSharedWithError('Please select employee for sharing.');
    }
  };

  // updates goal details and unshares with currently shared user
  const unshareGoal = async () => {
    setIsLoading(true);
    try {
      const creatorFlag = !goalIsForCurrentUser && createdForFlag;
      const user = { name: null, email: null, employeeId: null };
      const goalPromise = updateExistingGoal(user, creatorFlag, false);
      goalDispatch({
        type: 'setGoalFormConfig',
        payload: {
          shareToFlag: false
        }
      });
      const commentPromise = updateComments(goalId);
      await Promise.all([goalPromise, commentPromise]);
      refetchComments();
      setSharedWith({});
      setIsLoading(false);
      if (sharedWithCurrentUser) {
        redirectToGoalsDashboard();
      }
    } catch {
      handleError(ACTION.UNSHARE);
    }
  };

  const saveGoal = async () => {
    setIsLoading(true);
    try {
      let newGoalId;
      if (goalId) {
        newGoalId = goalId;

        const user = getShareToUser();
        await updateExistingGoal(user);
      } else {
        newGoalId = await createNewGoal();
      }

      await updateComments(newGoalId);
      redirectToGoalsDashboard();
    } catch {
      handleError(ACTION.SAVE);
    }
  };

  const getShareToUser = () => {
    // if a user has been selected to share to, use them
    if (shareToUser.name && shareToUser.email) return shareToUser;

    // retain the currently shared user
    if (goalId || goalIsForCurrentUser)
      return { name: undefined, email: undefined, employeeId: undefined };

    // auto-share with the goal creator
    return {
      name: userData.name,
      email: userData.email,
      employeeId: userData.employeeId
    };
  };

  const createNewGoal = async () => {
    const {
      categoryId,
      statusId,
      trackId,
      competencyId,
      completedDateObject,
      dueDateObject,
      startDateObject
    } = getGoalContents();

    // user being shared to needs visual indicator on dashboard
    const flagSharedTo =
      (shareToUser.email || shareToUser.employeeId) &&
      !idMatch(shareToUser.email, userData.email) &&
      !idMatch(shareToUser.employeeId, userData.email);

    const { data } = await createGoal({
      variables: {
        createdFor: goalForName,
        createdForEmail: goalForEmail,
        createdForRole: goalForRole,
        createdForJobNumber: goalForJobNumber,
        createdForEmployeeId: goalForEmployeeId,
        title: textField.goalTitle || goalTitle,
        description: textField.goalDescription || goalDescription,
        startDate: startDateObject,
        dueDate: dueDateObject,
        completedDate: completedDateObject,
        categoryId,
        competencyId,
        statusId,
        trackId,
        shareToName: getShareToUser().name,
        shareToEmail: getShareToUser().email,
        shareToEmployeeId: getShareToUser().employeeId,
        createdForFlag: !goalIsForCurrentUser,
        shareToFlag: flagSharedTo
      }
    });

    const newGoalId = data.createGoal.id;

    goalDispatch({
      type: 'setGoalFormConfig',
      payload: {
        goalId: newGoalId
      }
    });

    return newGoalId;
  };

  const updateComments = async id => {
    if (commentsToDelete.length) {
      await deleteGoalComments({
        variables: {
          ids: commentsToDelete
        }
      });
      setCommentsToDelete([]);
    }
    if (updatedCommentText) {
      await storeComment(id);
      setCommentText('');
      goalDispatch({
        type: 'setGoalFormConfig',
        payload: { commentText: '' }
      });
    }
  };

  const handleDeleteGoal = async () => {
    setIsLoading(true);
    if (goalId) {
      try {
        await deleteGoal({
          variables: {
            goalId,
            trackId: getSelectedOptionId(GOAL_TRACK, dropdownSelection.Track),
            categoryId: getSelectedOptionId(
              GOAL_CATEGORY,
              dropdownSelection.Category
            ),
            competencyId: getSelectedOptionId(
              allCompetenciesToDisplay(false),
              dropdownSelection.Competency
            )
          }
        });
        redirectToGoalsDashboard();
      } catch {
        handleError(ACTION.DELETE);
      }
    }
  };

  const handleUnshare = () => {
    if (!goalIsForCurrentUser) {
      return setOpenModal(MODAL.UNSHARE);
    }
    return unshareGoal();
  };

  // add or remove comments to delete based on checkbox state
  const gatherCommentsForDeletion = (id, checked) => {
    if (checked) {
      setCommentsToDelete([...commentsToDelete, id]);
    } else {
      const index = commentsToDelete.indexOf(id);
      if (index > -1) {
        const newCommentsToDelete = commentsToDelete.filter(commentId => {
          return commentId !== id;
        });
        setCommentsToDelete(newCommentsToDelete);
      }
    }
  };

  const competencyUrl = COMPETENCY_URL.ALL;
  const competencyUrlToDisplay = (
    <Link href={competencyUrl} target="_blank" rel="noopener noreferrer">
      View Descriptions
    </Link>
  );

  return (
    <>
      <div className={classes.root}>
        <AutoScroll type="scrollToTop" />
        <section className={classes.goalEntryFields}>
          <div className={`${classes.sections} ${classes.auditContainer}`}>
            <div className={classes.title}>
              <GoalTitleAndDescription
                initialValue={goalTitle}
                setGoalTitleOrDescription={setGoalTitleOrDescription}
                disabled={!currentUserCanEdit()}
                label="Goal Title"
                idTag="goalTitle"
              />
            </div>
            <div className={classes.goalFor}>
              <TextField
                variant="outlined"
                label="Goal For"
                data-cy="goalFor"
                disabled
                value={goalForName || ''}
              />
            </div>
          </div>
          <GoalDatesSection
            dateInput={dateInput}
            handleDateSelection={handleDateSelection}
            disabled={!currentUserCanEdit()}
          />
          {/* Dropdowns Section */}
          <div className={`${classes.sections} ${classes.selectContainer}`}>
            <div className={`${classes.dropdown} ${classes.category}`}>
              <Dropdown
                labelName="Category"
                id="Category"
                labelwidth={70}
                options={getDropdownSelectionOptions(GOAL_CATEGORY)}
                defaultValue={
                  dropdownSelection.Category || GOAL_CATEGORY.NONE.NAME
                }
                onChange={selectDropdownOption('Category')}
                dropdownLocation="goalForm"
                disabled={!currentUserCanEdit()}
              />
            </div>
            <div className={`${classes.dropdown} ${classes.category}`}>
              <Dropdown
                labelName="Competency"
                id="Competency"
                labelwidth={90}
                options={getDropdownSelectionOptions(
                  allCompetenciesToDisplay(false)
                )}
                defaultValue={
                  dropdownSelection.Competency || allCompetenciesToDisplay(true)
                }
                onChange={selectDropdownOption('Competency')}
                dropdownLocation="goalForm"
                disabled={!currentUserCanEdit()}
              />
              <div className={classes.competencyUrlStyles}>
                {competencyUrlToDisplay}
              </div>
            </div>
            <div className={`${classes.dropdown} ${classes.status}`}>
              <Dropdown
                labelName="Status"
                labelwidth={45}
                options={getDropdownSelectionOptions(GOAL_STATUS)}
                defaultValue={
                  dropdownSelection.Status || GOAL_STATUS.NOT_STARTED.NAME
                }
                onChange={selectDropdownOption('Status')}
                dropdownLocation="goalForm"
                disabled={!currentUserCanEdit()}
              />
            </div>
            <div className={`${classes.dropdown} ${classes.track}`}>
              <Dropdown
                labelName="Track"
                labelwidth={40}
                options={getDropdownSelectionOptions(GOAL_TRACK)}
                defaultValue={
                  dropdownSelection.Track || GOAL_TRACK.NO_FLAG_SET.NAME
                }
                onChange={selectDropdownOption('Track')}
                dropdownLocation="goalForm"
                disabled={!currentUserCanEdit()}
              />
            </div>
          </div>
          <div
            className={`${classes.sections} ${classes.descriptionContainer}`}
          >
            <div className={classes.description}>
              <GoalTitleAndDescription
                initialValue={goalDescription}
                setGoalTitleOrDescription={setGoalTitleOrDescription}
                disabled={!currentUserCanEdit()}
                label="Description"
                idTag="goalDescription"
              />
            </div>
          </div>
          <GoalCommentSection
            comment={updatedCommentText}
            setCommentText={setCommentText}
            prevCommentsData={
              prevCommentsData && prevCommentsData.fetchGoalComments
            }
            gatherCommentsForDeletion={gatherCommentsForDeletion}
          />
          <div
            className={
              (!idMatch(goalForEmail, userData.email) &&
                !idMatch(goalForEmployeeId, userData.employeeId)) ||
              !goalId
                ? classes.hidden
                : classes.actionLinkSection
            }
          >
            <DeleteForever className={classes.actionIcon} />
            <Link
              component="button"
              variant="body2"
              data-cy="deleteBtn"
              onClick={() => setOpenModal(MODAL.DELETE)}
              className={classes.actionLink}
            >
              Delete Goal
            </Link>
          </div>
        </section>
        {/* cannot immediately share if making a goal for someone else */}
        {(goalIsForCurrentUser || goalId) && (
          <ShareGoalSection
            sharedWithError={sharedWithError}
            setSharedWithError={setSharedWithError}
            sharedWith={sharedWith}
            setShareToUser={setShareToUser}
          />
        )}
        <section className={classes.buttonContainer}>
          <div className={classes.leftBtnGroup}>
            <Button
              onClick={
                unsavedGoalChanges
                  ? () => setOpenModal(MODAL.CANCEL)
                  : () => redirectToGoalsDashboard()
              }
              variant="contained"
              className={`${classes.button} ${classes.cancelBtn}`}
              data-cy="cancelBtn"
            >
              <CancelOutlined />
              CANCEL
            </Button>
          </div>
          <div className={classes.rightBtnGroup}>
            {/* share option not available if creating a goal for someone else */}
            {(goalIsForCurrentUser || goalId) && (
              <Button
                onClick={sharedWith.name ? handleUnshare : shareGoal}
                variant="contained"
                id="shareBtn"
                className={`${classes.button} ${classes.shareBtn}`}
                data-cy="shareBtn"
                disabled={!goalTitle}
              >
                <Share />
                {sharedWith.name ? 'UNSHARE' : 'SHARE'}
              </Button>
            )}
            <Button
              onClick={() => saveGoal()}
              variant="contained"
              className={`${classes.button} ${classes.saveBtn}`}
              data-cy="saveBtn"
              disabled={!goalTitle}
            >
              <SaveOutlined />
              SAVE
            </Button>
          </div>
        </section>
        {cancelModalIsOpen() && (
          <CancelModal close={closeModal} action={redirectToGoalsDashboard} />
        )}
        {deleteModalIsOpen() && (
          <DeleteModal close={closeModal} action={handleDeleteGoal} />
        )}
        {errorModalIsOpen() && <ErrorModal close={closeModal} error={error} />}
        {unshareModalIsOpen() && (
          <UnshareModal close={closeModal} action={unshareGoal} />
        )}
        <AutoScroll type="scrollToBottom" />
      </div>
    </>
  );
};

export default GoalForm;
