import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Button, FormControl, CircularProgress } from '@material-ui/core';
import { Share } from '@material-ui/icons';

import { useAuth } from '../../context/AuthContext';
import { useGoalState } from '../../context/GoalContext';
import { useGoalsDashboardSearchState } from '../../context/GoalsDashboardSearchContext';
import {
  FETCH_GOALS_FOR_USER,
  FETCH_GOALS_FOR_ROLE
} from '../../graphql/goalQueries';
import { BULK_SHARE_GOALS } from '../../graphql/goalMutations';
import {
  GOAL_DASH_VIEWS,
  GOAL_CATEGORY,
  allCompetenciesToDisplay
} from '../../helpers/constants';
import GoalsReviewTable from '../../components/goals/GoalsReviewTable';
import GoalsDashboardSearchBar from '../../components/goals/GoalsDashboardSearchBar';
import {
  LoadingSpinner,
  Modal,
  DropdownSuggest
} from '../../components/common';
import GoalsViewSelect from '../../components/goals/GoalsViewSelect';
import {
  dashboardTitleDisplay,
  truncateDate,
  getErrorMessage,
  idMatch
} from '../../helpers/helpers';
import useStyles from '../../styles/common/dashboardStyles';

const GoalsDashboard = () => {
  const classes = useStyles();

  // State for handling bulk sharing of goals
  const [bulkSharing, setbulkSharing] = useState();
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sharingProgress, setSharingProgress] = useState(false);
  const [chosenUser, setChosenUser] = useState();

  const {
    state: { view }
  } = useGoalState();

  const {
    userData: { email, employeeId }
  } = useAuth();

  const {
    state: { goalsDashboardSearchResults }
  } = useGoalsDashboardSearchState();

  //  converts goal data into more useful format for the review table
  const formatDataForTable = goalData => {
    //  none in the database should read as select a category on the front end
    const chooseCategory = category => {
      return category === 'None' ? GOAL_CATEGORY.NONE.NAME : category;
    };
    const chooseCompetency = competency => {
      return competency === 'None'
        ? allCompetenciesToDisplay(true)
        : competency;
    };

    return goalData.map(goal => {
      const {
        id,
        createdFor,
        title,
        createdForEmail,
        createdForEmployeeId,
        createdForJobNumber,
        dueDate,
        shareToName,
        shareToEmail,
        description,
        startDate,
        completedDate,
        statusId: { displayName: status },
        trackId: { displayName: track },
        categoryId: { displayName: category },
        createdForFlag,
        shareToFlag
      } = goal;
      return {
        goalId: id,
        goalStatus: status,
        createdFor,
        title,
        createdForEmail,
        createdForEmployeeId,
        createdForJobNumber,
        dueDate: dueDate ? truncateDate(dueDate) : null,
        goalTrack: track,
        shareToName,
        shareToEmail,
        description,
        startDate,
        completedDate,
        goalCategory: chooseCategory(category),
        goalCompetency: chooseCompetency(
          goal.competencyId ? goal.competencyId.displayName : 'None'
        ),
        createdForFlag,
        shareToFlag
      };
    });
  };

  // view conditionals
  const viewIsMyGoals = view === GOAL_DASH_VIEWS.MY_GOALS;
  const viewIsSharedGoals = view === GOAL_DASH_VIEWS.SHARED_GOALS;

  // Mutations
  const [bulkShareGoals] = useMutation(BULK_SHARE_GOALS);

  // select database query based on current view
  const getQueryForView = select => {
    const queries = {
      [GOAL_DASH_VIEWS.MY_GOALS]: FETCH_GOALS_FOR_USER,
      [GOAL_DASH_VIEWS.SHARED_GOALS]: FETCH_GOALS_FOR_USER,
      [GOAL_DASH_VIEWS.BOARD_VIEW]: FETCH_GOALS_FOR_ROLE,
      [GOAL_DASH_VIEWS.EXECUTIVE_VIEW]: FETCH_GOALS_FOR_ROLE,
      [GOAL_DASH_VIEWS.MANAGER_VIEW]: FETCH_GOALS_FOR_ROLE
    };
    return queries[select];
  };

  const { data, loading, error, refetch } = useQuery(getQueryForView(view), {
    fetchPolicy: 'no-cache'
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    const message = getErrorMessage(error);
    throw new Error(`[ERROR] Failed to fetch Goals for user: ${message}`);
  }

  // filter results based on current view
  const getFilterForView = () => {
    const filterBy = {
      [GOAL_DASH_VIEWS.MY_GOALS]: goal =>
        idMatch(goal.createdForEmail, email) ||
        idMatch(goal.createdForEmployeeId, employeeId),
      [GOAL_DASH_VIEWS.SHARED_GOALS]: goal =>
        idMatch(goal.shareToEmail, email) ||
        idMatch(goal.shareToEmployeeId, employeeId)
    };
    return filterBy[view];
  };

  const formattedGoalData = () => {
    if (goalsDashboardSearchResults) {
      if (viewIsMyGoals || viewIsSharedGoals) {
        const filteredData = goalsDashboardSearchResults.filter(
          getFilterForView()
        );

        return formatDataForTable(filteredData);
      }
      return formatDataForTable(goalsDashboardSearchResults);
    }

    const dataFilteredByView =
      data.fetchGoalsForUser &&
      data.fetchGoalsForUser.filter(getFilterForView());

    return viewIsMyGoals || viewIsSharedGoals
      ? formatDataForTable(dataFilteredByView)
      : formatDataForTable(data.fetchGoalsForRole);
  };

  const resetBulkSharing = () => {
    setbulkSharing(false);
    setSharingProgress(false);
    setSelectedGoals([]);
    setSelectedRows([]);
    closeModal();
  };

  const handleBulkShare = async () => {
    const {
      displayName,
      mail,
      userPrincipalName,
      employeeId: shareId
    } = chosenUser;
    setSharingProgress(true);

    await bulkShareGoals({
      variables: {
        goalIds: selectedGoals,
        shareToEmail: mail || userPrincipalName,
        shareToName: displayName,
        shareToEmployeeId: shareId
      }
    });
    resetBulkSharing();
    refetch();
  };

  const allowBulkSharing = () => setbulkSharing(true);

  const chooseEmployee = user => setChosenUser(user);

  const closeModal = () => setModalIsOpen(false);

  const openModal = () => {
    if (selectedGoals.length) setModalIsOpen(true);
  };

  const renderBulkShareModal = () => {
    return (
      <Modal
        modalTitle="Share Goals"
        leftBtnText="Cancel"
        leftBtnClick={closeModal}
        rightBtnText="Share"
        rightBtnClick={handleBulkShare}
        isOpen={modalIsOpen}
        handleClose={closeModal}
        disabled={!chosenUser}
      >
        <FormControl variant="outlined" className={classes.formControl}>
          {sharingProgress ? (
            <CircularProgress className={classes.progress} />
          ) : (
            <DropdownSuggest
              labelName="Share With"
              onChange={chooseEmployee}
              errorMessage=""
            />
          )}
        </FormControl>
      </Modal>
    );
  };

  const getButtonClassName = () => {
    const btnClasses = selectedGoals.length ? classes.active : classes.inactive;
    return `${classes.bulkShareBtn} ${btnClasses}`;
  };

  return (
    <div className={classes.container}>
      <div className={classes.buttonContainer}>
        {viewIsMyGoals && (
          <Button
            onClick={bulkSharing ? openModal : allowBulkSharing}
            variant="contained"
            id="bulkShareBtn"
            className={getButtonClassName()}
            data-cy="bulkShareBtn"
          >
            {bulkSharing && <Share />}
            {bulkSharing ? 'SHARE' : 'SHARE GOALS'}
          </Button>
        )}
        <GoalsViewSelect view={view} />
      </div>
      <h1 className={classes.header}>{dashboardTitleDisplay(view, 'goals')}</h1>
      <GoalsDashboardSearchBar activeView={view} allGoalsData={data} />
      <GoalsReviewTable
        data={formattedGoalData()}
        selectable={bulkSharing}
        selectGoals={setSelectedGoals}
        selectedRows={selectedRows}
        selectRows={setSelectedRows}
      />
      {modalIsOpen && renderBulkShareModal()}
    </div>
  );
};

export default GoalsDashboard;
