import gql from 'graphql-tag';

// fetches competency for user
export const FETCH_COMPETENCY_FOR_USER = gql`
  query FetchCompetencyForUser {
    fetchCompetencyForUser {
      udCompetency
    }
  }
`;
