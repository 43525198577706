import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { GOAL_DASH_VIEWS } from '../helpers/constants';

const GoalStateContext = createContext();
const GoalDispatchContext = createContext();

const useGoalState = () => useContext(GoalStateContext);
const useGoalDispatch = () => useContext(GoalDispatchContext);

const initialState = {
  view: GOAL_DASH_VIEWS.MY_GOALS,
  goalFormConfig: {
    goalId: null,
    category: null,
    competency: null,
    track: null,
    status: null,
    shareToName: null,
    shareToEmail: null,
    shareToEmployeeId: null,
    goalForName: null,
    goalForEmail: null,
    goalForEmployeeId: null,
    goalForJobNumber: null,
    goalTitle: null,
    goalDescription: null,
    startDate: null,
    dueDate: null,
    completedDate: null,
    commentText: '',
    createdForFlag: false,
    shareToFlag: false
  },
  unsavedGoalChanges: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setView':
      return {
        ...state,
        view: action.payload
      };
    case 'setGoalFormConfig':
      return {
        ...state,
        goalFormConfig: {
          ...state.goalFormConfig,
          ...action.payload
        }
      };
    case 'resetGoalFormConfig':
      return {
        ...state,
        goalFormConfig: {
          goalId: null,
          category: null,
          competency: null,
          track: null,
          status: null,
          shareToName: null,
          shareToEmail: null,
          shareToEmployeeId: null,
          goalForName: null,
          goalForEmail: null,
          goalForEmployeeId: null,
          goalForJobNumber: null,
          goalTitle: null,
          goalDescription: null,
          startDate: null,
          dueDate: null,
          completedDate: null,
          commentText: '',
          createdForFlag: false,
          shareToFlag: false
        }
      };
    case 'setUnsavedGoalChanges':
      return {
        ...state,
        unsavedGoalChanges: action.payload
      };
    default:
      throw new Error();
  }
};

const GoalContextProvider = ({ children }) => {
  const [state, goalDispatch] = useReducer(reducer, initialState);

  return (
    <GoalStateContext.Provider value={{ state }}>
      <GoalDispatchContext.Provider value={{ goalDispatch }}>
        {children}
      </GoalDispatchContext.Provider>
    </GoalStateContext.Provider>
  );
};

GoalContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { GoalContextProvider, useGoalState, useGoalDispatch };
