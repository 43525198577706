import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 80,
    width: '85%',
    margin: 'auto',
    '& h1, h3, h5': {
      color: globalTheme.palette.primary.main,
      fontWeight: '400'
    },
    '& h3': {
      fontSize: '1.75rem',
      '@media print': {
        fontSize: '15px',
        color: 'black',
        fontWeight: 'bold'
      }
    },
    '& section': {
      width: '100%'
    },
    '&.MuiInputLabel': {
      color: 'black'
    },
    '& label.Mui-focused': {
      color: 'black'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: globalTheme.palette.neutral.main
      },
      '&.Mui-focused fieldset': {
        borderColor: globalTheme.palette.secondary.main
      },
      '&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        '@media print': {
          borderColor: 'black'
        }
      },
      '&.MuiInputLabel-outlined .MuiInputLabel-shrink': {
        fontWeight: 'bold'
      }
    },
    '& .MuiCardContent-root': {
      '@media print': {
        padding: 5
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      '& h1': {
        fontSize: '1.5rem'
      },
      '& h3': {
        fontSize: '1.2rem',
        fontWeight: 400,
        '@media print': {
          fontSize: '12px'
        }
      },
      width: '90%'
    }
  },
  statusBanner: {
    display: 'flex',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    width: '100vw',
    height: '15%',
    justifyContent: 'center',
    '@media print': {
      backgroundColor: globalTheme.palette.neutral.contrast
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      backgroundColor: globalTheme.palette.neutral.contrast,
      width: '100%'
    }
  },
  archiveDetails: {
    color: globalTheme.palette.neutral.contrast,
    fontSize: '1.2rem',
    margin: '2%',
    '@media print': {
      color: globalTheme.palette.neutral.dark
    },
    [theme.breakpoints.down('sm')]: {
      color: globalTheme.palette.neutral.dark,
      fontSize: '0.8rem'
    }
  },
  employeeInfo: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  generalInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    '@media print': {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%'
    }
  },
  internSignOff: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
    '@media print': {
      display: 'flex'
    }
  },
  formHeader: {
    textAlign: 'center'
  },
  ratingScaleContainer: {
    [theme.breakpoints.down('sm')]: {
      visibility: 'hidden',
      height: 0
    },
    '@media print': {
      visibility: 'visible',
      height: '100%'
    }
  },
  ratingScale: {
    overflow: 'hidden',
    fontSize: 'larger',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      listStyle: 'none',
      padding: '0',
      alignItems: 'center',
      '@media print': {
        display: 'flex',
        visibility: 'visible'
      }
    },
    '& :last-child': {
      border: 'none'
    }
  },
  rating: {
    margin: '0',
    borderRight: '1.5px solid grey',
    padding: '0px 1em',
    flexGrow: '1',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      border: 'none',
      padding: '5px 0',
      textAlign: 'left'
    },
    '@media print': {
      fontSize: '12px'
    }
  },
  competency: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'normal'
    },
    '@media print': {
      fontSize: '12px'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '85%',
    margin: 'auto',
    '& .MuiSvgIcon-root': {
      paddingRight: '3px'
    },
    '@media print': {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'unset',
      width: '100%',
      '& .MuiButtonBase-root': {
        borderRadius: 0,
        textAlign: 'center',
        width: '50%'
      },
      '& .MuiButton-label': {
        display: 'flex',
        flexDirection: 'column'
      }
    }
  },
  leftBtnGroup: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '48%'
    }
  },
  rightBtnGroup: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '52%'
    }
  },
  button: {
    margin: `${theme.spacing(5)}px ${theme.spacing(1)}px`,
    padding: theme.spacing(1.75),
    color: globalTheme.palette.neutral.contrast,
    [theme.breakpoints.down('sm')]: {
      margin: 0
    },
    '@media print': {
      display: 'none'
    }
  },
  updateNextEvalDateBtn: {
    backgroundColor: globalTheme.palette.primary.main,
    height: theme.spacing(7),
    margin: '6px',
    color: globalTheme.palette.neutral.contrast,
    '@media print': {
      display: 'none'
    },
    '&.Mui-disabled': {
      color: globalTheme.palette.neutral.contrast,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    [theme.breakpoints.down('sm')]: {
      '&.MuiButtonBase-root': {
        backgroundColor: globalTheme.palette.neutral.dark
      },
      '&.Mui-disabled': {
        color: globalTheme.palette.neutral.contrast,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
  },
  sendBtn: {
    backgroundColor: globalTheme.palette.primary.main,
    '&.Mui-disabled': {
      color: globalTheme.palette.neutral.contrast,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    [theme.breakpoints.down('sm')]: {
      '&.MuiButtonBase-root': {
        backgroundColor: globalTheme.palette.neutral.dark,
        width: '47%'
      },
      '&.Mui-disabled': {
        color: globalTheme.palette.neutral.contrast,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
  },
  saveDraftBtn: {
    backgroundColor: globalTheme.palette.primary.main,
    '&.Mui-disabled': {
      color: globalTheme.palette.neutral.contrast,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      '&.MuiButtonBase-root': {
        backgroundColor: globalTheme.palette.neutral.dark,
        width: '53%'
      },
      '&.Mui-disabled': {
        color: globalTheme.palette.neutral.contrast,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
  },
  cancelBtn: {
    backgroundColor: globalTheme.palette.primary.main,
    '&.Mui-disabled': {
      color: globalTheme.palette.neutral.contrast,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      '&.MuiButtonBase-root': {
        backgroundColor: globalTheme.palette.neutral.dark,
        width: '53%'
      },
      '&.Mui-disabled': {
        color: globalTheme.palette.neutral.contrast,
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
  },
  archiveBtnHiddenPublished: {
    display: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.down('sm')]: {
      display: 'unset',
      '& .MuiButton-label': {
        visibility: 'hidden'
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
  },
  archiveBtnHiddenUnpublished: {
    display: 'none',
    backgroundColor: globalTheme.palette.neutral.dark,
    [theme.breakpoints.down('sm')]: {
      display: 'unset',
      '& .MuiButton-label': {
        visibility: 'hidden'
      }
    },
    '&.Mui-disabled': {
      backgroundColor: globalTheme.palette.neutral.dark
    }
  },
  archiveBtnPublished: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    '&.Mui-disabled': {
      color: globalTheme.palette.neutral.contrast,
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  },
  archiveBtnUnpublished: {
    color: globalTheme.palette.neutral.contrast,
    backgroundColor: globalTheme.palette.neutral.dark
  },
  assignContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    marginBottom: 30,
    paddingTop: 30,
    borderWidth: 'thin',
    borderTopStyle: 'dashed',
    '@media print': {
      display: 'none'
    }
  },
  gridContainer: {
    justifyContent: 'space-between',
    '@media print': {
      justifyContent: 'flex-start'
    }
  },
  leftSideIntern: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 200
    }
  },
  rightSideIntern: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: 150
    }
  },
  divider: {
    backgroundColor: 'white'
  },
  actionLink: {
    color: globalTheme.palette.primary.main,
    marginTop: 25,
    fontSize: 20,
    fontWeight: 'bold',
    '@media print': {
      display: 'none'
    }
  },
  pdfLinkClosedView: {
    marginBottom: 100
  },
  actionLinkContainer: {
    display: 'flex'
  },
  actionIcon: {
    color: globalTheme.palette.primary.main,
    marginTop: 27,
    marginRight: 5,
    fontSize: 23,
    '@media print': {
      display: 'none'
    }
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '@media print': {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  goalsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  activeGoalsHeader: {
    color: globalTheme.palette.primary.main
  },
  goalTitle: {
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap'
  },
  goalMetadata: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: globalTheme.palette.neutral.medDark
  },
  goalDescription: {
    whiteSpace: 'pre-wrap'
  },
  noGoalsText: {
    marginBottom: 20
  }
}));

export default useStyles;
