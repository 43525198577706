import { makeStyles } from '@material-ui/core/styles';

import globalTheme from '../globalTheme';

const useStyles = makeStyles(theme => ({
  commentDetails: {
    backgroundColor: globalTheme.palette.neutral.lightAlpha,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    margin: '8px auto',
    '& p': {
      margin: '0.75em',
      fontStyle: 'italic',
      textTransform: 'uppercase'
    },
    '& .MuiInput-underline:before': {
      border: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:after': {
      borderColor: globalTheme.palette.secondary.main
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: globalTheme.palette.neutral.medDark,
      '@media print': {
        color: globalTheme.palette.neutral.dark
      }
    },
    '& .MuiInputBase-input': {
      '@media print': {
        paddingBottom: 0
      }
    },
    '& .MuiFormHelperText-root.MuiFormHelperText-contained': {
      '@media print': {
        display: 'none'
      }
    },
    '@media print': {
      backgroundColor: globalTheme.palette.neutral.light,
      borderRadius: 0,
      display: 'block',
      margin: 0,
      border: `1px solid ${globalTheme.palette.neutral.light}`,
      marginTop: 15
    }
  },
  textFieldContainer: {
    width: '95%',
    margin: '0.5em 1em'
  },
  textField: {
    width: '100%',
    '@media print': {
      fontSize: '12px',
      lineHeight: '200%'
    },
    '& .MuiFormHelperText-root.MuiFormHelperText-contained': {
      '@media print': {
        display: 'none'
      }
    }
  },
  disabled: {
    backgroundColor: globalTheme.palette.neutral.lightAlpha,
    borderRadius: '4px',
    '@media print': {
      display: 'none'
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: globalTheme.palette.neutral.medDark,
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        textTransform: 'none'
      }
    }
  },
  enterComment: {
    textTransform: 'uppercase',
    '& .MuiOutlinedInput-root': {
      '@media print': { padding: 5 }
    },
    '& .MuiFormLabel-root': {
      '@media print': { padding: 5, transform: 'none' }
    },
    '& .MuiOutlinedInput-root fieldset': {
      border: `.5px solid ${globalTheme.palette.neutral.light}`
    },
    '@media print': {
      margin: 5
    }
  },
  editable: {
    '& p': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        textTransform: 'none'
      }
    },
    backgroundColor: globalTheme.palette.neutral.contrast,
    border: `1.5px solid ${globalTheme.palette.neutral.light}`
  },
  closedStatus: {
    display: 'none'
  },
  commentCreator: {
    '@media print': {
      fontSize: '15px'
    }
  },
  checkBoxContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: globalTheme.palette.primary.main
  },
  uncheckedContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: globalTheme.palette.neutral.medDark,
    '@media print': {
      display: 'none'
    }
  },
  checkBox: {
    '&.Mui-checked ': {
      color: globalTheme.palette.primary.main
    }
  }
}));

export default useStyles;
