import gql from 'graphql-tag';

export const GOAL_INFO_DATA = gql`
  fragment GoalData on TblGoal {
    id
    statusId {
      displayName
    }
    categoryId {
      displayName
    }
    competencyId {
      displayName
      competencyGroup
    }
    trackId {
      displayName
    }
    title
    description
    createdFor
    createdForEmail
    createdForEmployeeId
    createdForJobNumber
    createdForRole
    shareToName
    shareToEmail
    shareToEmployeeId
    startDate
    dueDate
    completedDate
    createdForFlag
    shareToFlag
  }
`;

// Fetches all goals for users with roles lower than the current user
export const FETCH_GOALS_FOR_ROLE = gql`
  query FetchGoalsForRole {
    fetchGoalsForRole {
      ...GoalData
    }
  }
  ${GOAL_INFO_DATA}
`;

// Fetches all Goals for the current user or shared with the current user
export const FETCH_GOALS_FOR_USER = gql`
  query FetchGoalsForUser {
    fetchGoalsForUser {
      ...GoalData
    }
  }
  ${GOAL_INFO_DATA}
`;

export const FETCH_GOAL_COMMENTS = gql`
  query FetchGoalComments($goalId: Int!) {
    fetchGoalComments(goalId: $goalId) {
      id
      comment
      createdBy
      createdByEmail
      createdOn
      createdByEmployeeId
    }
  }
`;

export const FETCH_MATCHING_GOALS = gql`
  query FetchMatchingGoals(
    $dueDateSince: DateTime
    $dueDateUntil: DateTime
    $openSearch: String
    $status: [String]
    $category: [String]
    $competency: [String]
    $track: [String]
    $activeView: String
  ) {
    fetchMatchingGoals(
      dueDateSince: $dueDateSince
      dueDateUntil: $dueDateUntil
      openSearch: $openSearch
      status: $status
      category: $category
      competency: $competency
      track: $track
      activeView: $activeView
    ) {
      ...GoalData
    }
  }
  ${GOAL_INFO_DATA}
`;

export const GET_USER_ROLES = gql`
  query GetUserRoles($token: String!, $email: String!) {
    getUserRoles(token: $token, email: $email)
  }
`;
