import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';
import { useMutation } from '@apollo/react-hooks';

import userAgentApplication from '../ad-config/userAgentApplication';
import config from '../ad-config/azureConfig';
import { LOGIN_USER, TOKEN_REFRESH } from '../graphql/evalMutations';

const AuthContext = createContext();

const useAuth = () => useContext(AuthContext);

const AuthContextProvider = ({ children }) => {
  const [submitLogin] = useMutation(LOGIN_USER);
  const [tokenRefresh] = useMutation(TOKEN_REFRESH);

  const storedUserJWT = localStorage.getItem('userData');
  const storedUser = jwt.decode(storedUserJWT);
  const [isAuthenticated, setAuthenticated] = useState(storedUser != null);
  const [userData, setUserData] = useState(storedUser);

  const login = async () => {
    try {
      await userAgentApplication.loginPopup({
        scopes: config.scopes,
        prompt: 'select_account'
      });

      const fullToken = await fetchAccessToken();
      const stringifiedToken = JSON.stringify(fullToken);

      const {
        data: { loginUser }
      } = await submitLogin({
        variables: {
          input: { fullToken: stringifiedToken }
        }
      });

      const { token, user } = loginUser;

      saveUserData(token, user);
      setAuthenticated(true);
    } catch (error) {
      setAuthenticated(false);
      throw new Error(`[ERROR] Failed to fetch user tokens: ${error}`);
    }
  };

  const fetchAccessToken = () => {
    try {
      return userAgentApplication.acquireTokenSilent({
        scopes: config.scopes
      });
    } catch (error) {
      throw new Error(`[ERROR] Failed to fetch access token: ${error}`);
    }
  };

  // token has expired, retrieve new token
  const refreshLogin = async () => {
    // avoid calling refresh in test environment
    if (localStorage.length === 2) return localStorage.accessToken;

    let newToken;
    try {
      newToken = await fetchAccessToken();
    } catch (error) {
      if (error.name === 'InteractionRequiredAuthError') {
        try {
          newToken = await userAgentApplication.acquireTokenPopup({
            scopes: config.scopes
          });
        } catch (err) {
          throw new Error(`[ERROR] Refresh popup failed: ${error}`);
        }
      } else {
        throw new Error(`[ERROR] Failed to refresh login: ${error}`);
      }
    }
    const existingUser = localStorage.getItem('userData');
    const decodedUser = jwt.decode(existingUser);
    if (tokenExpiresSoon(decodedUser)) {
      try {
        const { data } = await tokenRefresh();
        localStorage.setItem('userData', data.tokenRefresh.user);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`[ERROR] Failed to refresh user token: ${error}`);
      }
    }
    localStorage.setItem('accessToken', newToken.accessToken);
    return newToken.accessToken;
  };

  const tokenExpiresSoon = user => {
    // if token is expiring within two hours
    return Date.now() >= (user.exp - 7200) * 1000;
  };

  const saveUserData = (token, user) => {
    localStorage.setItem('accessToken', token);
    localStorage.setItem('userData', user);

    const decodedUser = jwt.decode(user);
    setUserData(decodedUser);
  };

  const logout = () => {
    userAgentApplication.logout();
    localStorage.clear();
    setAuthenticated(false);
    setUserData(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userData,
        login,
        logout,
        refreshLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { AuthContextProvider, useAuth };
