import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';

import { useGoalDispatch } from '../../context/GoalContext';
import { Modal } from '../common';
import useStyles from '../../styles/common/initiateEvalStyles';

const RenewGoal = ({ isOpen, closeModal, renderType, goalInfo }) => {
  const { goalDispatch } = useGoalDispatch();

  const history = useHistory();
  const classes = useStyles();
  const [
    status,
    goalForName,
    goalTitle,
    ,
    ,
    ,
    ,
    description,
    ,
    ,
    category,
    ,
    email,
    ,
    ,
    ,
    ,
    competency
  ] = goalInfo;

  const renewGoal = user => {
    closeModal();
    goalDispatch({
      type: 'setGoalFormConfig',
      payload: {
        goalForName: goalForName,
        goalForEmail: email,
        status: status,
        goalDescription: description,
        goalForJobNumber: user ? user.jobNumber : null,
        goalForEmployeeId: user ? user.employeeId : null,
        goalTitle: goalTitle,
        category: category,
        competency: competency
      }
    });
    history.push('/goal');
  };

  const redirectToGoalDashboard = () => {
    history.push('/goalsDashboard');
  };

  // display modal if on desktop
  const renderModalVersion = () => {
    return (
      <Modal
        modalTitle="Renew Goal"
        leftBtnText="Cancel"
        leftBtnClick={closeModal}
        rightBtnText="Confirm"
        rightBtnClick={renewGoal}
        isOpen={isOpen}
        handleClose={closeModal}
      >
        <h3>Goal Title</h3>
        <Typography>{goalTitle}</Typography>
        <h3>Goal For</h3>
        <Typography>{goalForName}</Typography>
      </Modal>
    );
  };

  // display full page if on mobile
  const renderPageVersion = () => {
    return (
      <div className={classes.newEvalPageContainer}>
        <h2 className={classes.pageVersionTitle}>Renew Goal</h2>
        <h3>Goal Title</h3>
        <Typography>{goalTitle}</Typography>
        <h3>Goal For</h3>
        <Typography>{goalForName}</Typography>
        <div className={classes.actionButtons}>
          <Button
            className={`${classes.button} ${classes.negativeButton}`}
            onClick={redirectToGoalDashboard}
            variant="outlined"
            data-cy="cancelNewGoal"
          >
            Cancel
          </Button>
          <Button
            className={`${classes.button} ${classes.positiveButton}`}
            onClick={renewGoal}
            data-cy="createNewGoal"
          >
            Confirm
          </Button>
        </div>
      </div>
    );
  };

  return renderType === 'modal' ? renderModalVersion() : renderPageVersion();
};

RenewGoal.defaultProps = {
  isOpen: false,
  closeModal: () => {}
};

RenewGoal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  renderType: PropTypes.string.isRequired
};

export default RenewGoal;
